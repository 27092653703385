:root {
    --fontWeight-helvetica-light: 300;
    --fontWeight-helvetica-regular: 400;
    --fontWeight-helvetica-bold: 700;
    --fontWeight-helvetica-extra-bold: 900;
    --fontFamily-helvetica: 'Helvetica', sans-serif;
}
:root {
    --colorOrange: #EB6425;
    --colorBleu: #13ABE4;
    --colorVert: #85C141;
    --colorBlanche: #FFFFFF;
    --colorNoir: #292929;
    --colorModalContent:#F8F4F9;
}

.text-box {
	position: absolute;
	top: 50%;
	left: 15%;
	right: 15%;
	color: #fff;
	text-align: center;
	transform: translateY(-50%);
}
.Slider_carrousel h2{
    font-size: 55px;
    font-weight: var(--fontWeight-helvetica-bold);
    color: var(--colorBlanche);
}
.Slider_carrousel p{
    font-size: 17px;
    font-weight: var(--fontWeight-helvetica-regular);
    color: var(--colorBlanche);
}
.imgD{
    opacity: 0.9;   
}
/* #Media
================================================== */

@media (max-width: 767px) { 
    .Slider_carrousel{
        display: none !important;
    }
}