:root {
    --fontWeight-helvetica-light: 300;
    --fontWeight-helvetica-regular: 400;
    --fontWeight-helvetica-bold: 700;
    --fontWeight-helvetica-extra-bold: 900;
    --fontFamily-helvetica: 'Helvetica', sans-serif;
}
:root {
    --colorOrange: #EB6425;
    --colorBleu: #13ABE4;
    --colorVert: #85C141;
    --colorBlanche: #FFFFFF;
    --colorNoir: #292929;
    --colorModalContent:#F8F4F9;
}
.wrap-contact{
    background-color: rgba(235, 100, 37, .10);
    color: var(--colorOrange);
    margin: 60px 0 0 0;
    padding: 75px 0 75px 0;
}
.wrap-contact h1{
    font-weight: var(--fontWeight-helvetica-extra-bold);
    text-align: center;
}
.wrap-contact p{
    text-align: center;
    margin-top: 15px;
    font-weight: var(--fontWeight-helvetica-bold);
    color: var(--colorNoir);
}
.wrap-contact p a{
    text-decoration: none;
    color: var(--colorNoir);
}
.wrap-contact p a:hover{
    text-decoration: none;
    color: var(--colorOrange);
}
.phone h3{
    margin-top: 50px;
    color: var(--colorBleu);
    font-weight: var(--fontWeight-helvetica-bold);
    margin-bottom: 20px;
}
.phone h4{
    font-weight: var(--fontWeight-helvetica-bold);
    color: var(--colorNoir);
}
.cameroun img{
    width: 25px;
    height: 20px;
    border-radius: 5px;
}
.ivoire img{
    width: 25px;
    height: 20px;
    border-radius: 5px;
}


.warp-form{
    border-radius: 10px;
    background-color: var(--colorBlanche) !important;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px 0px;
    padding: 20px;
    text-align: center;
    margin-top: 50px;
}

.warp-form h3{
    text-align: center;
    font-weight: var(--fontWeight-helvetica-bold);
    color: var(--colorBleu) !important;
}
.warp-form button{
    background-color: var(--colorOrange) !important;
    color: var(--colorBlanche) !important;
    font-weight: var(--fontWeight-helvetica-bold);
}


/*******************Contact Dashboard********************/
.contact-Dash{
    overflow-x: hidden;
}
.wrap-contact-d{
    background-color: var(--colorBleu);
    color: var(--colorBlanche);
    margin: 60px 0 30px 0;
    padding: 50px 0 25px 0;
}
.wrap-contact-d h1{
    font-weight: var(--fontWeight-helvetica-extra-bold);
}

.container_dashboard{
    border-radius: 10px;
    background-color: var(--colorBlanche) !important;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px 0px;
    padding: 20px;
    margin-bottom: 10px;
}
.container_dashboard span{
    font-weight: var(--fontWeight-helvetica-bold);
}
.container_dashboard span strong{
    color: var(--colorOrange);
}

.container_dashboard button{
    color: var(--colorBlanche) !important;
    background-color: red !important;
    float: right !important;
}
.container_dashboard button:hover + .msg{
    display: block;
    
}
.msg{
    width: 100px;
    display: none;
    font-weight: var(--fontWeight-helvetica-bold);
    text-align: center;
    font-size: 12px;
    border-radius: 10px;
    padding: 5px;
    margin: 0px 10px 0px 0px;
    float: right;
    background-color: var(--colorNoir) !important;
    color: var(--colorBlanche) !important;
}

/* #Media
================================================== */

@media (max-width: 767px) {
    .warp-form{
        width: auto;
    }
    
}