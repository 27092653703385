:root {
    --fontWeight-helvetica-light: 300;
    --fontWeight-helvetica-regular: 400;
    --fontWeight-helvetica-bold: 700;
    --fontWeight-helvetica-extra-bold: 900;
    --fontFamily-helvetica: 'Helvetica', sans-serif;
}
:root {
    --colorOrange: #EB6425;
    --colorBleu: #13ABE4;
    --colorVert: #85C141;
    --colorBlanche: #FFFFFF;
    --colorNoir: #292929;
    --colorModalContent:#F8F4F9;
}
.section_discute{
    background-color: rgba(235, 100, 37, 0.10);
    height: 700px;
}
.wrap-content-discute{
    margin: 90px 0 25px 0;
    padding: 50px;
}
.wrap-content-discute h1{
    color: var(--colorBleu);
    font-weight: var(--fontWeight-helvetica-extra-bold);
}
.spaceOne{
    width: 100px;
    height: 5px;
    background-color: var(--colorOrange) !important;
    margin: 0px 0 20px 0;
}

.carousel-indicators li {
    /*display: inline-block;
    margin: 10px;
    text-indent: 0;
    cursor: pointer;
    border: none;*/
    background-color: var(--colorOrange);
    /*box-shadow: inset 1px 1px 1px 1px rgba(0,0,0,0.5);*/    
}
.carousel-indicators .active {
    /*margin: 10px;*/
    background-color: var(--colorOrange);
}
.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23EB6425' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}
   
.carousel-control-next-icon {
     background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23EB6425' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

/* #Media
================================================== */

@media (max-width: 767px) {
    .section_discute{
        background-color: rgba(235, 100, 37, 0.10);
        height: auto !important;
    }
    .wrap-content-discute{
        margin: 0px 0 0px 0;
        padding: 10px 0 10px 0;
    }
    #carouselExampleIndicators{
        padding: 0 0 20px 0;
    }
    .carousel-indicators{
        display: none;
    }
}
