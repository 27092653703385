:root {
	--fontWeight-helvetica-light: 300;
	--fontWeight-helvetica-regular: 400;
	--fontWeight-helvetica-bold: 700;
	--fontWeight-helvetica-extra-bold: 900;
	--fontFamily-helvetica: 'Helvetica', sans-serif;
}
:root {
	--colorOrange: #EB6425;
	--colorBleu: #13ABE4;
	--colorVert: #85C141;
	--colorBlanche: #FFFFFF;
	--colorNoir: #292929;
	--colorModalContent:#F8F4F9;
}
.wrap-login{
	width: 390px ;
	background-color: var(--colorBlanche) !important;
	border-top: 2px solid var(--colorOrange);
	border-bottom: 2px solid var(--colorOrange);
	padding: 15px;
	border-radius: 10px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}
.body-login{
	text-align: center;
}
.body-login img{
	width: 230px;
	height: auto;
}
.body-login h5{
	font-weight: var(--fontWeight-helvetica-bold);
	color: var(--colorBleu);
}
.btn-login{
	background-color: var(--colorOrange) !important;
	color: var(--colorBlanche) !important;
}


/* #Media
================================================== */

@media (max-width: 767px) { 
	.wrap-login{
		width: 100%;
	}
}


