:root {
	--fontWeight-helvetica-light: 300;
	--fontWeight-helvetica-regular: 400;
	--fontWeight-helvetica-bold: 700;
	--fontWeight-helvetica-extra-bold: 900;
	--fontFamily-helvetica: 'Helvetica', sans-serif;
  }

  :root {
	--colorOrange: #EB6425;
	--colorBleu: #13ABE4;
	--colorVert: #85C141;
	--colorBlanche: #FFFFFF;
	--colorNoir: #292929;
  --colorRed: #FC0404;
	--colorModalContent:#F8F4F9;
  }

  .wrap-Home-Dashboard{
    margin-top: 100px;
  }
  .table{
    width: 100%;
    border: 1px solid var(--colorModalContent);
  }
  .table_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 5px 20px 5px 20px;
    background-color: var(--colorBleu);
  }
  .table_header h1{
    text-align: center;
    font-weight: var(--fontWeight-helvetica-bold);
    color: var(--colorBlanche);
  }
  button{
    outline: none;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    padding: 10px;
    color: var(--colorBlanche);
  }
  td .color1{
    background-color: var(--colorOrange) !important;
    color: var(--colorBlanche) !important;
    border-radius: 6px;
    cursor: pointer;
  }
  td .color2{
    background-color: var(--colorRed) !important;
    color: var(--colorBlanche) !important;
    border-radius: 6px;
    cursor: pointer;
  }
  td .color3{
    background-color: var(--colorVert) !important;
    color: var(--colorBlanche) !important;
    border-radius: 6px;
    cursor: pointer;
  }

  td .color4{
    background-color: var(--colorBleu) !important;
    color: var(--colorBlanche) !important;
    border-radius: 6px;
    cursor: pointer;
  }

  /*.add_new{
    padding: 10px 20px;
    color: var(--colorBlanche);
    background-color: #0298cf !important;
  }*/
  /*input{
    padding: 10px 20px;
    margin: 0 10px;
    outline: none;
    border: 1px solid #0298cf;
    border-radius: 6px;
    color: #0298cf;
  }*/
  .table_section{
    height: 400px;
    overflow: auto;
  }
  table{
    width: 100%;
    table-layout: fixed;
    min-width: 900px;
    border-collapse: collapse !important;
  }
  thead th{
    position: sticky;
    top: 0;
    background-color: #6b6b6b;
    color: var(--colorBlanche);
    font-size: 15px;
    border-top: none !important;
  }
  th,td{
    border-bottom: 1px solid #dddddd !important;
    padding: 10px 20px;
    word-break: break-all !important;
    text-align: center;
  }
  /*td img{
    height: 60px;
    width: 60px;
    object-fit: cover;
    border-radius: 15px;
    border: 5px solid #ced0d2;
  }*/
  tr:hover td{
    color: var(--colorNoir);
    font-weight: var(--fontWeight-helvetica-bold);
    cursor: pointer;
    background-color: rgba(235, 100, 37, 0.40);
  }


  ::placeholder{
    color: #6b6b6b !important;
  }

  ::-webkit-scrollbar{
    height: 5px;
    width: 5px;
  }

  ::-webkit-scrollbar-track{
    box-shadow: inset 0 0 6px var(--colorOrange);
  }

  ::-webkit-scrollbar-thumb{
    box-shadow: inset 0 0 6px var(--colorOrange);
  }

  /* #Media
================================================== */

@media (max-width: 767px) { 
  ::-webkit-scrollbar-track{
    box-shadow: inset 0 0 6px var(--colorOrange);
  }

  ::-webkit-scrollbar-thumb{
    box-shadow: inset 0 0 6px var(--colorOrange);
  }
}

