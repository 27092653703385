:root {
    --fontWeight-helvetica-light: 300;
    --fontWeight-helvetica-regular: 400;
    --fontWeight-helvetica-bold: 700;
    --fontWeight-helvetica-extra-bold: 900;
    --fontFamily-helvetica: 'Helvetica', sans-serif;
}
:root {
    --colorOrange: #EB6425;
    --colorBleu: #13ABE4;
    --colorVert: #85C141;
    --colorBlanche: #FFFFFF;
    --colorNoir: #292929;
    --colorModalContent:#F8F4F9;
}
.wrap-propos{
    background-color: rgba(235, 100, 37, .10);
    color: var(--colorOrange);
    margin: 60px 0 0 0;
    padding: 75px 0 75px 0;
}
.wrap-propos h1{
    font-weight: var(--fontWeight-helvetica-extra-bold);
    text-align: center;
}

.wrap-propos p{
    text-align: center;
    margin-top: 15px;
    font-weight: var(--fontWeight-helvetica-bold);
    color: var(--colorNoir);
}
.wrap-propos p a{
    text-decoration: none;
    color: var(--colorNoir);
}
.wrap-propos p a:hover{
    text-decoration: none;
    color: var(--colorOrange);
}

.main{
    box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px 0px !important;
    border-radius: 10px;
    margin: 25px 0px 25px 0px;
    padding: 20px;

}
.main h4{
    font-weight: var(--fontWeight-helvetica-extra-bold);
    color: var(--colorBleu) !important;
}

.team1{
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px 0px !important;
}
.team1 .divimg img{
    width: 170px;
}
.description{
    padding: 20px 15px 20px 15px;
}
.description h5{
    font-weight: var(--fontWeight-helvetica-bold);
}

.social .facebook{
    color: #3b5998 !important;
    border: 2px solid #3b5998 !important;
    background-color: var(--colorBlanche) !important;
}
.social .whatsapp{
    color: rgb(43, 183, 65);
    border: 2px solid rgb(43, 183, 65);
    background-color: var(--colorBlanche) !important;
    margin: 0 10px 0 10px;
}
.social .telegram{
    color: #229ed9;
    border: 2px solid #229ed9;
    background-color: var(--colorBlanche) !important;
}

.social .facebook:hover{
    color: var(--colorBlanche) !important;
    border: 2px solid var(--colorOrange) !important;
    background-color: var(--colorOrange) !important;
}

.social .whatsapp:hover{
    color: var(--colorBlanche) !important;
    border: 2px solid var(--colorOrange) !important;
    background-color: var(--colorOrange) !important;
}
.social .telegram:hover{
    color: var(--colorBlanche) !important;
    border: 2px solid var(--colorOrange) !important;
    background-color: var(--colorOrange) !important;
}