:root {
    --fontWeight-helvetica-light: 300;
    --fontWeight-helvetica-regular: 400;
    --fontWeight-helvetica-bold: 700;
    --fontWeight-helvetica-extra-bold: 900;
    --fontFamily-helvetica: 'Helvetica', sans-serif;
}
:root {
    --colorOrange: #EB6425;
    --colorBleu: #13ABE4;
    --colorVert: #85C141;
    --colorBlanche: #FFFFFF;
    --colorNoir: #292929;
    --colorModalContent:#F8F4F9;
}

.wrap-profil{
    box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 15px 0px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    width: 500px;
    border-radius: 8px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}
.wrap-profil .text img{
  height: 170px;
  border-radius: 50%;
  margin-bottom:10px;
}

.color_admin{
    background-color: var(--colorOrange) !important;
    color: var(--colorBlanche) !important;
    font-weight: var(--fontWeight-helvetica-bold);
    float: right !important;
    text-align: center !important;
}
